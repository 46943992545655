export const name =
  `请输入原材料的商品名称。<br><br>
无论是通用的食材名称还是具体的商品名称，都没有关系。<br><br>
为了方便管理，请输入“易于理解的名称”。`;

export const isProcessed =
  `通过设置，将支持以下内容，并且更容易搜索。<br>
<br>
如果是加工食品，规定将原材料的原材料产地标为“◯◯制造”（◯◯为国内，都道府县名，其他众所周知的地名）。<br>
<br>
例）苹果汁（德国制造）<br>
<br>
如果是生鲜食品，规定标为「〇〇」（◯◯为国内，都道府县名，其他众所周知的地名）。<br>
<br>
例）苹果（德国）`;

export const displayName =
  `在此处写入的内容将在创建商品时按原样显示。<br><br>
例如，如果您在此处输入“酱油”，并在商品中选择该原材料，则会显示如下。 <br><br>
◆ 饭团<br>
原材料名：大米，酱油`;

export const yieldPercent =
  `输入购买商品的“可食用率”。<br><br>
例如，如果不使用“菠萝罐头”的“糖浆部分”，可食用率就会显示百分之几十。<br><br>
如果计算变得复杂，将原材料名称设置为“菠萝罐头（丢弃糖浆）”并将可食用率设置为 100% 也是有效的。`;

export const unit =
  `您可以在此处指定原材料的使用单位。<br><br>
通过指定这一点，在“中间原材料”和“商品”中，您可以指定像 0.5 个胡萝卜，而不是指定克或千克。<br><br>
比如像关于胡萝卜的指定方法，指定每根胡萝卜的重量，此处假设指定“150g”为单位数量，然后指定“根”为单位名称。`;

export const madeInAreaNumberPerishable =
  `请输入您使用的原材料的产地数。<br><br>
比如这个原材料是“橙子”，如果国内国外的都能使用，就输入“2个地方”。<br><br>
如果频繁切换，请输入实际业绩最多的地方。`;

export const madeInAreaNumberProcessed =
  `请输入该原材料的制造地数量。<br><br>
比如这个原材料是“番茄酱”，如果国内国外的都能使用，就输入“2个地方”。<br><br>
如果频繁切换，请输入实际业绩最多的地方。`;

export const madeInAreaNamePerishable =
  `如果有两个以上的生产地，则需要从使用最多的生产地开始输入。<br><br>
如果原材料产地是“海外”，则需要选择具体的国家名称。 <br><br>
此外，如果您想写一个具体的地名，例如“大米（新泻）”，则有详细的设置规则。<br><br>
・如果是农产品，则是都道府县名和其他众所周知的地名 <br><br>
・如果是肉类产品，则是主要养殖地（养殖期最长的地方）所属的都道府县名和其他众所周知的地名。<br><br>
・如果是海产品，则是其产地（包括捕捞和收获）的水域名称，上岸港口名称，主要养殖场（水产养殖时间最长的地方）所属的都道府县名，以及其他众所周知的地名。<br><br>
如果选项中没有可选的项目，请选择“国内（自由输入）”或“海外（自由输入）”，并输入满足上述条件的文字。`;

export const madeInAreaNameProcessed =
  `如果有两个以上的生产地，则需要从使用最多的生产地开始输入。<br><br>
如果原材料产地是“海外”，则需要选择具体的国家名称。<br><br>
另外，如果要在原材料上显示“番茄酱（北海道产）”等具体地名，则规定应使用都道府县名和其他众所周知的地名。<br><br>
如果选项中没有可选的项目，请选择“国内（自由输入）”或“海外（自由输入）”，并输入满足上述条件的文字。`;

export const isCompositeHidden =
  `例如，蛋黄酱主要是由“鸡蛋，醋，油”等成分制成。<br>
<br>
像这样从原材料的显示名可以想象到大部分内容，则允许在食品标示中省略对复合原材料的()描述。<br>
<br>
在这里打勾，您可以对其进行省略。<br>
<br>
---------------------------------<br>
【原材料名】蛋黄酱（鸡蛋，酿醋，食用植物油），白砂糖（甘蔗，甜菜），盐<br>
---------------------------------<br>
↓<br>
---------------------------------<br>
【原材料名】蛋黄酱，白砂糖，盐<br>
---------------------------------`;

export const isCompositeCanBeSplitted =
  `所谓分类标记，如果复合原材料的内容“在原材料的性质上没有明显变化”，则可以将每种原材料划分为使用该复合原材料的商品的原材料名称。<br>
<br>
例）◯ 可可调制品→白砂糖，可可粉，杏仁粉<br>
<br>
但是，如果性质不同，则不允许对原材料进行分类标记。<br>
<br>
例）✕ 小豆馅→白砂糖，小豆，糖浆，琼脂 `;

export const compositeItemName =
  `如果商品中使用该原材料，请在食品标示的的“原材料名称”中注明该标示名。<br>
<br>
---------------------------------<br>
【原材料名】○○，△△，蛋黄酱（食用植物油，蛋黄（包括鸡蛋），食用醋），□□，xx<br>
---------------------------------<br>
<br>
上述中，“食用植物油”，“蛋黄”，“食用醋”为符合的选项。<br>
<br>
该显示名称，可以进行自由输入。`;

export const similarNameAlert =
  `
这种原材料很可能是“添加剂”！<br>
<br>
如果是添加剂，则需要将其登陆为添加剂，因此请确认是否可以在下方“添加剂”一栏中进行输入。`;

export const allergenToOmitDisplay =
  `如果“原材料标示名”选择“牛肉”，“过敏原”选择“牛肉”，可能会发生以下情况。<br>
<br>
---------------------------------<br>
【原材料名】○○，△△，牛肉（包含牛肉），□□，××<br>
---------------------------------<br>
<br>
像这样当“原材料标识名”中明确包含“过敏原名称”时，在仅针对过敏原的个别标示时，就可以简单地只标示“牛肉”。<br>
<br>
---------------------------------<br>
【原材料名】○○，△△，牛肉，□□，××<br>
---------------------------------<br>
<br>
但是，在过敏原的批量标示中，则需要标注“（一部分包含牛肉）”。<br>
<br>
---------------------------------<br>
【原材料名】○○，△△，牛肉，□□，××，（一部分包含牛肉）<br>
---------------------------------<br>
<br>
此外，在称为“牛肉（包含牛肉）”等“替代标记”或“牛筋（包含牛肉）”等“放大标记”时，也可以将其省略。 <br>
<br>
如果您想在标示中省略这些过敏原的标示，并在过敏原的批量标示中一并标记它们，请选择此项。<br>
<br>
但是，因为过敏原的标示会影响到消费者的生命，所以当难以确定是否含有过敏原时，就不要选择。<br>
<br>
尤其关于蛋黄和蛋白，过敏原的“鸡蛋”是不允许省略的。例) ✕ 蛋白, ◯ 蛋白 (包括鸡蛋)`;

export const gmo =
  `如果原材料经过基因改造并且属于以下任何一种情况，您必须选择“符合”。<br>
<br>
如果符合，请阅读以下说明。<br>
<br>
  1. 大豆（包括毛豆和豆芽）<br>
  2. 玉米<br>
  3. 马铃薯<br>
  4. 油菜籽<br>
  5. 棉籽<br>
  6. 苜蓿<br>
  7. 甜菜<br>
  8. 木瓜<br>
<br>
◆ 以大豆为主要原材料的加工制品<br>
<br>
  1. 豆腐类及炸豆腐类<br>
  2. 冻豆腐、豆渣和腐竹<br>
  3. 纳豆<br>
  4. 豆乳类<br>
  5. 味增<br>
  6. 煮豆<br>
  7. 罐装大豆和瓶装大豆<br>
  8. 黄豆粉<br>
  9. 炒豆子<br>
  10. 以上述 1 至 9 项为主要原材料的食品<br>
  11.以调理用的大豆为主要原材料的食品 <br>
  12. 以大豆粉为主要原材料的食品<br>
  13. 以大豆蛋白质为主要原材料的食品<br>
<br>
◆ 以毛豆为主要原材料的加工食品<br>
<br>
  1. 以毛豆为主要原材料的食品<br>
<br>
◆ 以豆芽为主要原材料的加工食品<br>
<br>
  1. 以豆芽为主要原材料的食品<br>
<br>
◆ 以玉米为主要原材料的食品<br>
<br>
  1. 玉米零食<br>
  2. 玉米淀粉<br>
  3. 爆米花<br>
  4. 冷冻玉米<br>
  5. 罐装玉米和瓶装玉米<br>
  6. 以上述1~5项为主要原材料的食品<br>
  7. 以玉米粉为主要原材料的食品<br>
  8. 以玉米面为主要原材料的食品（不包括玉米片）<br>
  9. 由烹饪用的玉米为主要原材料的食品<br>
<br>
◆ 以马铃薯为主要原材料的食品<br>
<br>
  1. 土豆零食<br>
  2. 干土豆（干土豆泥等）<br>
  3. 冷冻马铃薯（冷冻炸薯条、冷冻土豆泥）<br>
  4. 马铃薯淀粉<br>
  5. 以上述1~4项为主要原材料的食品<br>
  6. 以调理用马铃薯味主要原材料的食品<br>
<br>
◆ 以苜蓿为主要原材料的食品 <br>
<br>
  1. 以苜蓿为主要原材料的食品 <br>
<br>
◆ 以甜菜为主要原材料的食品<br>
<br>
  1. 以调理用甜菜为主要原材料的食品<br>
<br>
◆ 以木瓜为主要原材料的食品<br>
<br>
  1. 以木瓜为主要原材料的食品<br>
<br>
---<br>
<br>
在“符合”的情况下，选择符合的“农作物”，然后从以下选项中选择符合的的“标示”。<br>
<br>
◆ 当符合的农作物为“分别生产流通管理”时 <br>
<br>
・如果使用的原材料是转基因的，请选择“基因重组” 的选项<br>
<br>
・如果使用的原材料不是转基因的，请选择“不是基因重组” 的选项<br>
<br>
◆ 当符合的农作物不为“分别生产流通管理”时<br>
<br>
・请选择“不分别基因重组” 的选项<br>
<br>
◆ 其他当具有“高油酸”或“硬脂酸生产”特征的产品时<br>
<br>
・请选择“高油酸基因重组”或“混合高油酸基因重组”的选项<br>
<br>
◆ 具有其他“高赖氨酸”特征时 <br>
<br>
・请选择“高赖氨酸基因重组”或“混合高赖氨酸基因重组”的选项
`;

export const selectAdditive =
  `在商品中使用这种原材料时，请描述商品的“原材料”中记载的“添加剂”。<br>
<br>
任何不在选项中的，都被法律禁止使用。<br>
<br>
符合以下条件的，也请进行输入。在生成商品时，可以将其个别设置为不显示。<br>
<br>
① 营养强化目的<br>
如它的字面意思，是为了“增强营养”而添加的成分。<br>
<br>
② 加工助剤<br>
1. 在该食品完成之前去除的成分<br>
2. 与该食品中通常含有的成分相同且不会明显增加成分量的成分<br>
3. 含有少量且不影响该食物的成分<br>
<br>
③ 结转<br>
1. 用于制造或加工该食品原材料过程中使用的成分<br>
2. 当該食品の製造または加工の過程において使用されないもの<br>
3. 该食品中比添加物所发挥的效果的量还要少的成分`;

export const additiveOrigin =
  `如果是由以过敏原为原材料制成的添加剂，则根据《食品标示法》规定，需要在食品标示中标注“卵磷脂（来源于大豆）”。<br>
<br>
一般来说，通过确认食品标示和规格书（如果没有说明来源，请咨询购买者）来确认使用的食品，是否来自过敏原。`;

export const additivePurpose =
  `对于一部分添加剂，有义务标注“使用说明”。 <br><br>
在这种情况下，从这里选择您实际使用的“用途”。<br><br>
在没有必要显示的情况下，则显示“没有选择项目”。`;

export const invalidAdditive =
  `如果在商品中使用选择的添加剂，很有可能是非法的。<br><br>

请向购买该原材料的进口商或制造商具体咨询“添加剂的物质名称”。<br><br>
如果您对此有任何疑问，请随时通过“屏幕右下方>咨询/提问”来与我们进行联系。`;

export const referredNutrition =
  `如果您不知道超市里贩卖的“胡萝卜”等原材料的营养成分。<br><br>
或者，如果您想了解，如“糖浆腌橘子”的“糖浆”部分的详细的营养成分时。<br><br>
你可以引用文科省公布的《食品成分数据库》中的数据。<br><br>
()中的数值为文科省估算的数值。<br><br>
[]中的数值为文科省计算的估算数值。<br><br>
对于此处未列出的项目，请从原材料的商品企划书或者其他的情报信息中获取后，手动输入“热量”等数值。`;

export const intraCode = '如果已分配有管理 ID，请在此处设置管理 ID。';
