<template>
  <ElInput :placeholder="placeholder"
            prefix-icon="icon-search"
            @focus="focus = true" @blur="focus = false"
            @keydown.enter.native.capture="handleKeydownEnter"
            @compositionstart.native.capture="preventEnter"
            @compositionend.native.capture="enableEnter"
            class="el-input--medium rounded input-search" v-model="c_value"
            cy="search">
    <template #suffix>
      <svg v-if="focus" width='40' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 170" fill="#ccc">
        <path class="cls-1" d="M503.496,0H8.50391A8.5289,8.5289,0,0,0,0,8.50391V161.496A8.52891,8.52891,0,0,0,8.50391,170H503.496A8.529,8.529,0,0,0,512,161.496V8.50391A8.529,8.529,0,0,0,503.496,0ZM107.9726,51.93262H62.63666V80.29395h35.7168V94.93457H62.63666v28.92676H107.9726v15.20605H43.47015V36.79688H107.9726Zm102.76465,87.13477h-9.83105a8.71109,8.71109,0,0,1-3.74854-.707,8.33,8.33,0,0,1-2.8999-2.5459L140.789,67.7041q.21167,2.26465.31787,4.49121.1062,2.228.10645,4.06738v62.80469H124.45111V36.79688h9.97217a17.47428,17.47428,0,0,1,2.12207.10645,4.97672,4.97672,0,0,1,1.52051.42383,5.15066,5.15066,0,0,1,1.30859.91992,14.97778,14.97778,0,0,1,1.41455,1.59082l53.68115,68.39258q-.28345-2.47412-.38916-4.84473-.1062-2.36865-.106-4.41992V36.79688h16.76221Zm92.72119-86.63965H272.69281v86.63965H253.66742V52.42773H222.7597V36.79688h80.69873Zm76.59473-.49512H334.71722V80.29395H370.434V94.93457h-35.7168v28.92676h45.33594v15.20605H315.55072V36.79688h64.50244Zm79.84961,87.13477a8.34633,8.34633,0,0,1-4.209-.99023,8.5911,8.5911,0,0,1-2.93555-2.8291l-21.5-32.74609a7.56886,7.56886,0,0,0-2.65234-2.61719,9.30952,9.30952,0,0,0-4.27881-.77734H416.1933v39.96H397.16791V36.79688h31.19043a65.17667,65.17667,0,0,1,17.9292,2.15723A34.21384,34.21384,0,0,1,458.52289,45.001a23.34324,23.34324,0,0,1,7.001,9.30078,32.56475,32.56475,0,0,1,.707,21.67773,28.22351,28.22351,0,0,1-4.38477,8.3457,29.48774,29.48774,0,0,1-7.07129,6.57715,37.09481,37.09481,0,0,1-9.5835,4.52734,18.83235,18.83235,0,0,1,6.22314,5.87012L477.018,139.06738Z"/>
        <path class="cls-1" d="M449.15179,67.63379q0-7.77979-5.12744-11.95312-5.12915-4.17187-15.666-4.17285h-12.165V85.457h11.88232a29.462,29.462,0,0,0,9.37109-1.34375,18.117,18.117,0,0,0,6.57764-3.71289,14.92,14.92,0,0,0,3.85449-5.623A19.43832,19.43832,0,0,0,449.15179,67.63379Z"/>
      </svg>
    </template>
  </ElInput>
</template>

<script>
  import IMEHandler from '@/components/mixins/IMEHandler';

  export default {
    mixins: [IMEHandler],
    props: {
      placeholder: String,
      value: null,
    },
    data() {
      return {
        focus: false,
      }
    },
    computed: {
      c_value: {
        set(val) { this.$emit('input', val); },
        get() { return this.value; }
      }
    },
    methods: {
      onPressEnter() {
        this.$emit('search', this.c_value);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .input-search {
    margin-left: 60px;
    margin-right: 60px;
    width: 100%;

    ::v-deep {
      .el-input__prefix {
        left: 19px;
      }
      .el-input__icon {
        font-size: 13px;
        width: fit-content;
      }
      .el-input__inner {
        padding-left: 43px;
      }
      .el-input__suffix {
        padding-right: 19px;
        transition: none;
      }
    }
  }
</style>
