import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import IngredientEntity from "@/entities/ingredient-entity";

import AttachmentEntity from "@/entities/attachment-entity";
import {IPaginator} from "@/contracts/i-paginator";
import ObjectUtils from "@/utils/object-utils";
import {AllergenList} from "@/entities/allergy-entity";
import AuditEntity from "@/entities/audit-entity";
import FileSaver from "file-saver";
import {IFileUpload} from "@/contracts/i-file";
import CompanyDepartmentEntity from "@/entities/company-department-entity";

export type IngredientListType = {
  id: number;
  companyOrd: number;
  name:string;
  displayName: string;
  isPreproduct: boolean;
  intraCode: string | null;
  tags: string[];
  yieldPercent: number | null;
  costPerKg: number | null;
};

export default class IngredientRepository extends RepositoryBase<IngredientEntity> {
  protected ctor:new(data) => IngredientEntity = IngredientEntity;

  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/ingredients`
  }

  public async findById(id, params = {}): Promise<IngredientEntity>  {
    return await super.findById(id, params);
  }

  public async count():Promise<number> {
    const res = await this.get(`${this.endpoint}/count`);
    return Number(res.data);
  }

  public async search(query, params): Promise<IPaginator<IngredientForIndex>>  {
    const p = Object.assign({_as_pagination: true, q:query}, params);
    const res = await this.get(this.endpoint + "/search", p);
    return this.toPagination(res, IngredientForIndex);
  }

  public async list(excludeId:number|undefined = undefined): Promise<IngredientListType[]>  {
    const res = await this.get(this.endpoint + '/list');
    return res.data.filter(d => d.id !== excludeId);
  }

  public async getAttachments(id:number): Promise<AttachmentEntity[]> {
    const res = await this.get(this.endpoint + `/${id}/attachments`);
    return res.data.map(a => new AttachmentEntity(a));
  }

  public async getAuditLogs(id:number): Promise<AuditEntity[]> {
    const res = await this.get(this.endpoint + `/${id}/audits`);
    return res.data.map(a => new AuditEntity(a));
  }

  public async getReferencedProducts(id:number): Promise<ReferencedProducts> {
    const res = await this.get(this.endpoint + `/${id}/get-products-referencing-the-ingredient`);
    return res.data as ReferencedProducts;
  }
  public async downloadProductsReferencingTheIngredients(idList:number[], fileName: string) {
    const res = await this.get(this.endpoint + `/download-products-referencing-the-ingredients`, idList, {
      responseType: 'blob',
    });
    FileSaver.saveAs(new Blob([res.data], { type: res.data.type } ), fileName);
  }

  public async createFromTemplate(templateId) {
    await this.post(`${this.endpoint}/from-template/${templateId}`);
  }

  public async bulkImport(data: any[]): Promise<{counts: { target:number; updated: number} }> {
    const res = await this.put(`${this.endpoint}/bulk-import`, { data: data });
    return res.data;
  }

  public async lock(id:number): Promise<void> {
    await this.put(`${this.endpoint}/${id}/lock`);
  }
  public async unlock(id:number): Promise<void> {
    await this.put(`${this.endpoint}/${id}/unlock`);
  }
}

export class IngredientForIndex {
  public id!:number;
  public companyOrd!:number;
  public name!:string;
  public intraCode!:string|null;
  public displayName!:string;
  public ingredientTypeName!:string;
  public madeInAreaNames!:string[];
  public costPerKg!:number|null;
  public yieldPercent!:number;
  public costPerKgComputed!:number|null;

  public nutrition!:{
    calorie:string|null;
    protein:string|null;
    lipid:string|null;
    carb:string|null;
    natrium:string|null;
    salt:string|null;
  }|null;

  public note!:string|null;
  public updatedAt!:string;

  private allAllergenIds!:number[];
  public allAllergenNames!:string[];

  public hasAdditive!:boolean;
  public hasReferenceSpec!:boolean;
  public hasReferenceSpecUpdate!:boolean;

  public referencedProducts!:ReferencedProducts;

  public tags: string[] = [];
  public departments!: CompanyDepartmentEntity[];

  constructor(init:IngredientForIndex) {
    ObjectUtils.assignLiteralFields(this, init);

    this.madeInAreaNames = init.madeInAreaNames;
    this.allAllergenNames = init.allAllergenIds.map(allergenId => AllergenList.find(a => a.id === allergenId)!.name );
    this.nutrition = init.nutrition;
    this.referencedProducts = init.referencedProducts;

    this.tags = init.tags || [];
    this.departments = init.departments || [];
  }

  public getDepartmentIds(): number[] {
    return this.departments.map(d => d.id);
  }
}

export interface ReferencedProducts {
  products: {id:number; name:string;}[];
  preproducts: {id:number; name:string;}[];
}
