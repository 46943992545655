import Qs from 'qs';
import { Component, Watch } from 'vue-property-decorator';
import {IPaginator} from "@/contracts/i-paginator";
import LoadingHandler from '@/utils/loading-handler';
import IngredientRepository from "@/repositories/company/ingredient-repository";
import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {AxiosResponse} from "axios";
import AuthViewBase from "@/views/AuthViewBase";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import {dispatchInitialized} from "@/libs/cypress";

// TODO: refactor 継承させない
@Component
export default class ListPageBase extends AuthViewBase {
  public dataList: IPaginator<any>|null = null;
  public selectedRows:any[] = [];
  public order:{prop:string, isDesc:boolean} = {prop: 'id', isDesc: true};
  public routeName: string = '';

  protected fetchIngredientCount:boolean = false;
  protected ingredientCount:number|any = null;
  protected searchText:string = '';
  public searchLimit = 50;
  protected initialized = false;

  protected getRepository():RepositoryBase<any>{ return null as any;} ;
  protected loading:boolean = false;

  protected company:CompanyEntity = null as any;

  public getCurrentGETParameters() { return Qs.parse(location.search.substring(1)) as any; }

  private getCurrentPage(): number {
    const pageParam = this.getCurrentGETParameters().page;
    return pageParam ? Number(pageParam) : 1;
  }

  private d_currentSearchText:string = '';
  protected set currentSearchText(query:string) {
    this.assignParamToUrl({query: query || undefined});
    this.d_currentSearchText = query;
  }
  protected get currentSearchText() {
    return this.d_currentSearchText;
  }

  public async created() {
    this.d_currentSearchText = this.getCurrentGETParameters().query || '';
    this.searchText = this.getCurrentGETParameters().query || '';

    const getIngredientCountPromise = async () => {
      if (this.fetchIngredientCount) {
        this.ingredientCount = await (new IngredientRepository(this.companyId)).count();
      }
    };
    LoadingHandler(() => Promise.all([
        getIngredientCountPromise(),
        this.onCreating(),
        this.load(),
        new CompanyRepository().find(this.companyId).then(com => {
          this.company = com;
        }),
      ])
    ).then(async () => {
      await this.onCreated();
      dispatchInitialized();
    })
  }

  protected async onCreating() { /** please override */}
  protected async onCreated() {
    this.initialized = true;
  }

  protected async find(searchText, opt:IFindOption): Promise<any> { /** please override **/ }

  protected async search() {
    if (this.currentSearchText === this.searchText) return;
    this.currentSearchText = this.searchText;
    return await this.paginate();
  }

  protected clearFilter() {
    this.searchText = '';
    this.search();
  }

  protected async paginate(nextPage:number = 1) {
    this.assignParamToUrl({page: nextPage <= 1 ? undefined : nextPage});
    await LoadingHandler(this.load);
  }

  protected async load() {
    this.loading = true;
    try {
      this.dataList = await this.find(this.currentSearchText, this.getSearchOpt()) as any;
    } finally {
      this.loading = false;
    }
  }
  protected getSearchOpt(): IFindOption {
    return {
      _page: this.getCurrentPage(),
      _sort: this.order.prop,
      _limit: this.searchLimit,
      _order: this.order.isDesc ? 'desc' : 'asc',
    };
  }

  private assignParamToUrl(param) {
    const paramStr = Qs.stringify({ ...this.getCurrentGETParameters(), ...param } );
    const newUrl = this.$route.path + (paramStr ? `?${paramStr}` : '');
    history.replaceState(null, null as any, newUrl);
  }

  protected async onSortChange(prop) {
    if (this.order.prop === prop) {
      this.order.isDesc = !this.order.isDesc;
    } else {
      this.order.prop = prop;
      this.order.isDesc = true;
    }
    await this.load();
  }

  protected getOrderClass(prop:string) {
    if(this.order.prop === prop) {
      if (this.order.isDesc) {
        return 'el-icon-arrow-up u-color-primary u-bold';
      } else {
        return 'el-icon-arrow-down u-color-primary u-bold';
      }
    } else {
      return 'el-icon-arrow-down u-color-gray-quaternary';
    }
  }

  private handleSelectionChange(val) {
    this.selectedRows = val;
  }

  public deleteSelected(idKey:string) {
    this.confirmDeleteSelected(idKey).then(() => {
      const idList = this.selectedRows.map((i:any) => i[idKey]);
      return LoadingHandler(() => {
        return this.getRepository().destroyBulk(idList)
          .then((res:AxiosResponse) => {
            if (res.status !== 200) return;
            this.selectedRows.forEach(row => {
              this.dataList!.data.splice(this.dataList!.data.indexOf(row), 1);
            });
          });
      }).then(() => {
        this.$message({type: 'info', message: this.$t('削除しました')});
      });
    });
  }
  protected confirmDeleteSelected(idKey:string) {
    return new Promise<void>((resolve, reject) => {
      this.$confirm(
        this.$t(`選択されたn項目を一括で削除しますか？(この操作はもとに戻せません)`, [this.selectedRows.length]),
        this.$t('一括削除'),
        {
          confirmButtonText: this.$t('削除'),
          cancelButtonText: this.$t('キャンセル'),
        }
      )
        .then(async () => {
          resolve();
        })
        .catch(err => { if (err !== 'cancel' && err !== 'close') reject(err); });
    });
  }
  public cloneSelected() {
    if (this.selectedRows.length !== 1) return;

    const id = this.selectedRows[0].id;
    this.$router.push({name: `${this.routeName}.clone`, params: {id: id}});
  }
}

