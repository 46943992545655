import * as Ja from './ja/ingredient-create';
import * as En from './en/ingredient-create';
import * as Cn from './cn/ingredient-create';
import {getTextByLang} from "@/bootstraps/locale";

export const help = {
  name: getTextByLang({
    ja: Ja.name,
    en:En.name,
    cn:Cn.name
  }),
  isProcessed: getTextByLang({
    ja: Ja.isProcessed,
    en: En.isProcessed,
    cn: Cn.isProcessed
  }),
  displayName: getTextByLang({
    ja: Ja.displayName,
    en:En.displayName,
    cn:Cn.displayName
  }),
  displayNameAdditiveFormulation: getTextByLang({
    ja: Ja.displayNameAdditiveFormulation,
    // TODO: LANG
  }),
  yieldPercent: getTextByLang({
    ja: Ja.yieldPercent,
    en:En.yieldPercent,
    cn:Cn.yieldPercent
  }),
  unit: getTextByLang({
    ja: Ja.unit,
    en:En.unit,
    cn:Cn.unit
  }),
  madeInAreaNumberPerishable: getTextByLang({
    ja: Ja.madeInAreaNumberPerishable,
    en:En.madeInAreaNumberPerishable,
    cn:Cn.madeInAreaNumberPerishable
  }),
  madeInAreaNumberProcessed: getTextByLang({
    ja: Ja.madeInAreaNumberProcessed,
    en:En.madeInAreaNumberProcessed,
    cn:Cn.madeInAreaNumberProcessed
  }),
  madeInAreaNamePerishable: getTextByLang({
    ja: Ja.madeInAreaNamePerishable,
    en:En.madeInAreaNamePerishable,
    cn:Cn.madeInAreaNamePerishable
  }),
  madeInAreaNameProcessed: getTextByLang({
    ja: Ja.madeInAreaNameProcessed,
    en:En.madeInAreaNameProcessed,
    cn:Cn.madeInAreaNameProcessed
  }),
  isCompositeHidden: getTextByLang({
    ja: Ja.isCompositeHidden,
    en:En.isCompositeHidden,
    cn:Cn.isCompositeHidden
  }),
  isCompositeCanBeSplitted: getTextByLang({
    ja: Ja.isCompositeCanBeSplitted,
    en:En.isCompositeCanBeSplitted,
    cn:Cn.isCompositeCanBeSplitted
  }),
  compositeItemName: getTextByLang({
    ja: Ja.compositeItemName,
    en:En.compositeItemName,
    cn:Cn.compositeItemName
  }),
  similarNameAlert: getTextByLang({
    ja: Ja.similarNameAlert,
    en:En.similarNameAlert,
    cn:Cn.similarNameAlert
  }),
  allergenToOmitDisplay: getTextByLang({
    ja: Ja.allergenToOmitDisplay,
    en:En.allergenToOmitDisplay,
    cn:Cn.allergenToOmitDisplay
  }),
  gmoType: getTextByLang({
    ja: Ja.gmo,
    // TODO: LANG
  }),
  selectAdditive: getTextByLang({
    ja: Ja.selectAdditive,
    en:En.selectAdditive,
    cn:Cn.selectAdditive
  }),
  additiveOrigin: getTextByLang({
    ja: Ja.additiveOrigin,
    en:En.additiveOrigin,
    cn:Cn.additiveOrigin
  }),
  additivePurpose: getTextByLang({
    ja: Ja.additivePurpose,
    en:En.additivePurpose,
    cn:Cn.additivePurpose
  }),
  invalidAdditive: getTextByLang({
    ja: Ja.invalidAdditive,
    en:En.invalidAdditive,
    cn:Cn.invalidAdditive
  }),
  itemVisible: getTextByLang({
    ja: Ja.itemVisible,
    // TODO: LANG
  }),
  referredNutrition: getTextByLang({
    ja: Ja.referredNutrition,
    en:En.referredNutrition,
    cn:Cn.referredNutrition
  }),
  isNutritionOverrideOnPreproduct: getTextByLang({
    ja: Ja.isNutritionOverrideOnPreproduct,
    // TODO: LANG
  }),
  intraCode: getTextByLang({
    ja: Ja.intraCode,
    en:En.intraCode,
    cn:Cn.intraCode
  }),
  tag: getTextByLang({
    ja: Ja.tag,
    // TODO: LANG
  }),
  referSpec: getTextByLang({
    ja: Ja.referSpec,
    // TODO: LANG
  }),
};

